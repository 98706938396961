<template>
    <div class='scroll'>
        <img src="../assets/zd1.jpg?1" class="scroll_box" @click="zd()">
    </div>
</template>

<script>
export default {
    components: {},
    data() {
        return {};
    },
    methods: {
        zd() {
            window.scrollTo(0, 0);
        }
    }
}
</script>
<style lang="less">
.scroll {
    .scroll_box {
        width: 2.6rem;
        height: 2.6rem;
        padding: 1rem;
        border-radius: 50%;
        background-color: #fff;
        box-shadow: 0px 1px .8rem 0px rgba(0, 0, 0, 0.1);
        position: fixed;
        bottom: 6.6rem;
        right: 2rem;
    }
}
</style>