<template>
    <div class="log" @click.prevent="goKf()">
        <div class="log_box fl1" @click.stop.prevent="goIndex()">{{ times }}s 跳过</div>
        <!-- <div class="log_box fl1">{{ times }}s</div> -->
    </div>
    <!-- <div class="login" ref="login">
        <div class="login-head" v-if="!this.show">
            <i class="iconfont icon-jiantou3" @click="backpage"></i>
        </div>
        <div class="login-form">
            logo
            <div class="logo">
                <img src="../../assets/kk.jpg" class="img" />
            </div>
            输入手机号
            <div class="input-box">
                <i class="iconfont icon-jurassic_user"></i>
                <input type="text" placeholder="请输入您的用户名/手机号" class="input" v-model="username" />
            </div>
            输入密码
            <div class="input-box" v-if="this.show">
                <i class="iconfont icon-mima"></i>
                <input placeholder="请输入登录密码" :type="pwdFlag ? 'password' : 'text'" class="input" v-model="password" />
            </div>
             验证码
            <div class="input-box" v-else>
                <i class="iconfont icon-yanzhengma"></i>
                <input type="tel" maxlength="6" placeholder="请输入短信验证码" class="input" v-model="code" />
                <div class="send" ref="send" @click="getCode" v-if="this.shows">获取验证码</div>
                <div class="send" ref="send" v-else>60s后重新获取</div>
            </div>
            登录按钮 
            <div class="signup-button">
                <button class="btn btn-lg-blue" @click="login" v-show="this.show">账号密码登录</button>
                <div class="btn btn-bor-blue" @click="toRegister" ref="btn">短信快捷登录</div>
            </div>
        </div>
    </div> -->
</template>

<script>
import { getT } from '@/api/sys'
import { getSystz } from "@/api/sys"
import wx from 'weixin-js-sdk'
import { getIndex } from "../../api/index"
import { getToken, getInlet } from "../../api/index"
// import { Toast } from "vant"
// import getLogin  from "@/api/index.js"
export default {
    components: {},
    data() {
        return {
            key: '',
            int: null,
            times: 3,
            main: '',
            endDate: '',
            listData: [{ title: '兴科物联 智慧充电' }],
            staDate: null,
            tem: '',
            isMobile: false,
            // code: null,
            // username: "",
            // password: "",
            // checked: true,
            // pwdFlag: true,
            // show: true,
            // shows: true,
        };
    },
    methods: {
        // 入口配置
        async inlet() {
            // 获取URL的查询参数,将其转换为一个对象
            const searchParams = new URLSearchParams(window.location.search);
            const params = Object.fromEntries(searchParams.entries());
            this.key = params.key;
            let data1 = await getToken({ vender_key: this.key });
            if (data1) {
                if (data1.data.code == 1) {
                    this.main = data1.data.data.main_vender_id;
                    localStorage.setItem("main", data1.data.data.main_vender_id);
                    localStorage.setItem("token", data1.data.data.token);
                    localStorage.setItem("type", data1.data.data.vender_type);
                    // 如果没有商户进件转页面    
                    // if (data1.data.data.need_create != 0) {
                    //     this.$router.push({ path: "/loinx",query: {
                    //         url:data1.data.data.headimgurl,
                    //         name:data1.data.data.name,
                    //         phone:data1.data.data.phone,
                    //         need:data1.data.data.need_create,
                    //     }});
                    // }else{
                    if (localStorage.getItem("token")) {
                        // 获取入口 
                        let data = await getInlet({ token: localStorage.getItem("token") });
                        if (data.data.code == 1) {
                            this.worktable = data.data.data.check_worktable;
                            if (this.worktable == 1) {
                                this.$router.push({
                                    path: "/index",
                                    query: {
                                        int: this.isMobile,
                                        listData: this.listData,
                                        tem: this.tem,
                                    }
                                });
                            } else if (this.worktable == 0) {
                                this.$router.push({ path: "/menu" });
                            }
                        }
                    }
                    // }
                }
            } else {
                let data = await getT();
                if (data.data.code == 1) {
                    window.location.href = data.data.data;
                    localStorage.clear();
                }
            }
        },
        goIndex() {
            clearInterval(this.int1);
            this.iss();
            this.isMobile = this.iss();
            this.inlet();
            localStorage.setItem("key", this.key);
        },
        goKf() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.match(/MicroMessenger/i) == "micromessenger") {
                wx.miniProgram.getEnv((res) => {
                    if (res.miniprogram) {
                        wx.miniProgram.navigateTo({
                            url: '/pages/paykf/index?vender_id=' + this.main,
                        });
                        return;
                    }
                })
            }
        },
        async get() {
            let data = await getSystz({ token: localStorage.getItem('token') });
            if (data.data.code == 1) {
                this.listData = data.data.data;
            }
        },
        async getDates() {
            let data = await getIndex({ token: localStorage.getItem("token"), start_time: this.staDate, end_time: this.endDate });
            if (data.data.code == 1) {
                let tempData = data.data.data;
                this.tem = tempData;
            }
        },
        iss() {
            const reg = /(Android|iPhone|iPad|Windows Phone)/i;
            const userAgent = navigator.userAgent;
            return reg.test(userAgent);
        },
        // backpage() {
        //     this.$router.go(-1);
        // },
        // toRegister() {
        //     this.show = false;
        //     // this.$refs.btn.style = "background : #4393fa";
        //     // this.$refs.btn.style = "color : #fff";
        //     this.$refs.btn.className = "btn btn-bor-blue add"
        // },
        // isType() {
        //     this.pwdFlag = !this.pwdFlag;
        // },
        // login() {
        //     this.show = true;
        //     let userobj = {
        //         username: this.username,
        //         password: this.password
        //     };
        //     getLogin(userobj).then(data => {
        //         //   if (data.data.status == "success") {
        //         //     Toast.success("登录成功");
        //         //     localStorage.setItem("token", data.data.data);
        //         //     this.$router.push({
        //         //       path: "/index"
        //         //     });
        //         //   } else {
        //         //     Toast(data.data.errors);
        //         //   }
        //     });
        // },
        // // 获取验证码
        // getCode() {
        //     this.shows = false;
        //     setTimeout(() => {
        //         this.shows = true;
        //     }, 60000)
        //     let sendObj = {
        //     };
        //     // postuserCode(qs.stringify(sendObj)).then(data => {
        //     //     if (data.data.status == "success") {
        //     //         Toast.success("发送成功");
        //     //     } else {
        //     //         Toast(data.data.errors.message);
        //     //     }
        //     // });
    },
    created() {
        this.staDate = this.$moment().startOf('month').format('YYYY-MM-DD');
        this.endDate = this.$moment().subtract(0, 'months').format('YYYY-MM-DD');
        localStorage.clear();
    },
    mounted() {
        this.getDates();
        this.get();
        this.iss();
        this.isMobile = this.iss();
        this.int1 = setInterval(() => {
            this.times--;
            if (this.times <= 0) {
                clearInterval(this.int1);
                this.inlet();
            }
        }, 1000)
        if (this.$route.query.id == -8) {
            getT().then((data) => {
                if (data.data.code == 1) {
                    window.location.href = data.data.data;
                    localStorage.clear();
                }
            })
        }
        // this.$refs.login.style.height = window.innerHeight + "px";
        // this.$refs.login.style.width = window.innerWidth + "px";
    }
}
</script>
<style lang="less">
.log {
    width: 100%;
    height: 100vh;
    background: url(../../assets/111.png) 0 0 no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .log_box {
        padding: .7rem 1rem;
        top: 1.6rem;
        right: 1.4rem;
        position: absolute;
        color: #fff;
        justify-content: center;
        border-radius: 1rem;
        background-color: rgba(0, 0, 0, 0.5);
    }
}

// .login {
//     background-color: #fff;
//     padding: 3rem 3rem;
//     width: 100%;
//     height: 100vh;
//     box-sizing: border-box;

//     .login-head {
//         padding: 1rem 0rem;
//     }

//     .logo {
//         width: 8rem;
//         height: 8rem;
//         border-radius: 50%;
//         overflow: hidden;
//         margin: 15% auto;

//         img {
//             width: 100%;
//             display: block;
//         }
//     }

//     .input-box {
//         height: 3rem;
//         line-height: 3rem;
//         padding: .5rem 0;
//         display: flex;
//         align-items: center;
//         border-bottom: .1rem solid #dcdcdc;
//         margin-bottom: 5%;

//         i {
//             margin-right: 1rem;
//             color: #666;
//             height: 3rem;
//             line-height: 2.6rem;
//             font-size: 2rem;
//         }

//         input {
//             flex: 1;
//         }

//         .pwd-show {
//             color: red;
//         }
//     }

//     .privacy {
//         display: flex;
//         margin: 2rem auto;
//     }

//     .signup-button {
//         margin-top: 10%;

//         .btn {
//             height: 4rem;
//             padding: 0;
//             border-radius: 2rem;
//             line-height: 4rem;
//             margin-bottom: 5%;
//             
//             color: #fff;
//             border: 0;
//             text-align: center;
//             width: 100%;
//         }

//         .btn-lg-blue {
//             background: #4393fa;
//         }

//         .btn-bor-blue {
//             border: .1rem solid #4393fa;
//             color: #4393fa;
//             font-weight: 700;
//         }

//         .tips {
//             font-size: 1.2rem;
//             color: #333;
//             text-align: center;

//             .go-register {
//                 margin-left: .5rem;
//                 color: #4393fa;
//                 cursor: pointer;
//             }
//         }
//     }

//     .add {
//         background-color: #4393fa;
//         color: #fff !important;
//     }
// }</style>